import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

//import HomeView from '../views/Home.vue'
//import orderForm from '../components/DK/orderForm'
//import GroupORD from '../components/DK/GroupORD'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: '/',
    name: 'orderForm',
    //component: orderForm
    // component: () => import('../components/DK/orderForm/index')
    component: () => import('../views/ServiceStop')
  },

  {
    path: '/stop',
    name: 'ServiceStop',
    //component: orderForm
    component: () => import('../views/ServiceStop')
  },

  {
    path: '/group-ord/:smartlink',
    name: 'GroupORD',
    //component: GroupORD
    component: () => import('../components/DK/GroupORD/index')
  },

  {
    path: '/report',
    name: 'report',
    //component: orderForm
    component: () => import('../components/DK/report/index'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/prices',
    name: 'pricePlanner',
    //component: orderForm
    component: () => import('../components/DK/pricePlanner/pricePlanner.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/pay',
    name: 'managePay',
    //component: orderForm
    component: () => import('../components/DK/managePay'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/login',
    name: 'LoginDK',
    //component: orderForm
    component: () => import('../components/DK/LoginDK/index')
  },
  // {
  //   path: '/orderForm/',
  //   name: 'orderForm',
  //   component: () => import('@/components/__TEST/orderForm')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
